import React from 'react';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';
import { Typography } from '@pankod/refine-antd';
import {
  CommonSettingsIcon,
  ExchangeOrderIcon,
  LogoutIcon,
  P2P_My_Announce_Icon,
  P2P_My_Deal_Icon,
  P2P_View_Side_Icon,
  P2pOrdersIcon
} from '../../icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAuthState } from '../../../../useAuthState';
import { useCookiesCustom } from '../../../hooks/useCookieCustom';
import { ACCOUNT_URL } from 'packages/keycloak-client/constants'
import { CommonProfileControlIcon } from 'assets/icons'

export type IconComponent =
  | React.ComponentType<
      CustomIconComponentProps | React.SVGProps<SVGSVGElement>
    >
  | React.ForwardRefExoticComponent<CustomIconComponentProps>;

interface IBaseSideElement {
  pathKey: string;
  iconComponent: (color?: string) => JSX.Element;
  title: string;
}

export interface ISidebarElement {
  pathKey: string;
  iconComponent: (color?: string) => JSX.Element;
  title: string;
  childerns?: IBaseSideElement[];
  onClick?: () => void;
}

interface ISidebarCategories {
  name: string;
  elements: ISidebarElement[];
}

const DrawSideBarMenuElement = (
  title: string,
  icon: IconComponent,
  isSelected: boolean,
  isSubmenu?: boolean,
  hasChild?: boolean
) => (
  <ContainerStyled paddingLeft={hasChild ? 15 : 10}>
    <IconStyled component={icon} size={!isSubmenu ? 16 : 10} />
    <TextStyled color={isSelected ? '' : '#767687'}>{title}</TextStyled>
  </ContainerStyled>
);

interface IUseGetSidebar {
  path?: string;
  params: any;
}

export const useGetSideBarElements = ({ path, params }: IUseGetSidebar) => {
  const { t } = useTranslation();
  const logout = useAuthState((state) => state.logout);

  // const WALLET_ELEMENTS: ISidebarElement[] = [
  //   {
  //     pathKey: '/coin-accounts',
  //     iconComponent: WalletIcon,
  //     title: t('sidebar.wallet.overview'),
  //   },
  //   {
  //     pathKey: `/coin-accounts/${params.coin ?? 'btc'}`,
  //     iconComponent: Transaction,
  //     title: t('sidebar.wallet.transactions'),
  //   },
  // ];

  const ORDER_ELEMENTS: ISidebarElement[] = [
    // {
    //   pathKey: '/my-orders',
    //   iconComponent: ExchangeOrderIcon,
    //   title: t('sidebar.myDeals.exchange'),
    // },
    {
      pathKey: '/p2p/my-deals',
      iconComponent: P2pOrdersIcon,
      title: t('sidebar.myDeals.p2p'),
    },
  ];

  const SIDEBAR_CATEGORIES: ISidebarCategories[] = [
    // {
    //   name: t('sidebar.wallet.wallet'),
    //   elements: WALLET_ELEMENTS,
    // },
    {
      name: t('sidebar.myDeals.myDeals'),
      elements: ORDER_ELEMENTS,
    },
    // TODO: Вернуть при необходимости
    // {
    //   name: t('sidebar.profile.profile'),
    //   elements: PROFILE_ELEMENTS,
    // },
  ];

  const P2P_SIDEBAR_ELEMENTS: ISidebarElement[] = [
    {
      pathKey: '/p2p/dashboard',
      title: t('p2pSidebar.overview'),
      iconComponent: P2P_View_Side_Icon,
    },
    {
      pathKey: '/p2p/my-announcements',
      title: t('p2pSidebar.myAds'),
      iconComponent: P2P_My_Announce_Icon,
    },
    {
      pathKey: '/p2p/my-deals',
      title: t('p2pSidebar.myDeals'),
      iconComponent: P2P_My_Deal_Icon,
    },
    {
      pathKey: `${ACCOUNT_URL}/server/account/settings`,
      title: t('p2pSidebar.accountSettings'),
      iconComponent: CommonProfileControlIcon,
    },
  ];

  const P2P_Categories: ISidebarCategories[] = [
    {
      name: t('header.menu.trading.p2pTrading'),
      elements: P2P_SIDEBAR_ELEMENTS,
    },
  ];

  const BOTTOM_SIDEBAR_ELEMENTS: ISidebarElement[] = [
    // TODO: Скрыто
    // {
    //   pathKey: '/faq',
    //   title: 'FAQ',
    //   iconComponent: InfoCircle,
    // },
    // {
    //   pathKey: '/help',
    //   title: translate('bottomSidebar.help'),
    //   iconComponent: QuestionCircleIcon,
    // },
    {
      pathKey: '',
      title: t('bottomSidebar.exit'),
      iconComponent: LogoutIcon,
      onClick: () => {
        logout();
      },
    },
  ];

  return {
    DrawSideBarMenuElement,
    SIDEBAR_CATEGORIES: path?.includes('p2p')
      ? P2P_Categories
      : SIDEBAR_CATEGORIES,
    BOTTOM_SIDEBAR_ELEMENTS: path?.includes('p2p')
      ? [
          {
            pathKey: '/p2p/settings',
            title: t('settings'),
            iconComponent: CommonSettingsIcon,
          },
          ...BOTTOM_SIDEBAR_ELEMENTS,
        ]
      :
      BOTTOM_SIDEBAR_ELEMENTS,
  };
};

const ContainerStyled = styled.div<{ paddingLeft: number }>`
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: ${({paddingLeft}) => paddingLeft}px;
`;

const IconStyled = styled(Icon)`
  &.anticon {
    width: 16px;
    justify-content: center;
  }
`

const TextStyled = styled(Typography.Text)<{ color: string }>`
    margin-inline-start: 0;
    color: ${({color}) => color};
`;
